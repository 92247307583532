define("livarava-web/routes/dashboard", ["exports", "livarava-web/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Title
    titleToken: 'Dashboard',
    // Model
    model: function model() {
      var store = this.store;
      var meId = parseInt(Ember.get(this, 'me.id'));
      return Ember.RSVP.hash({
        user: store.findRecord('user', meId),
        recent: store.query('neuron', {
          author: meId,
          per_page: 6,
          page: 1,
          sort: '-created,-axon_count,header'
        }),
        valuable: store.query('neuron', {
          author: meId,
          per_page: 6,
          page: 1,
          sort: '-axon_count,-created,header'
        })
      });
    }
  });
  _exports.default = _default;
});