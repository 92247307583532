define("livarava-web/templates/components/user/user-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "qpsG7VC4",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"card card-user\"],[12],[2,\"\\n\"],[6,[37,2],null,[[\"route\",\"model\"],[\"neuron.item.overview\",[35,0,[\"id\"]]]],[[\"default\"],[{\"statements\":[[6,[37,1],[[35,0,[\"imageUrl\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"img\"],[14,0,\"card-img-top image-fluid\"],[15,\"src\",[31,[[34,0,[\"imageUrl\"]]]]],[15,\"alt\",[31,[[34,0,[\"fullname\"]]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"img\"],[14,0,\"card-img-top image-fluid\"],[14,\"src\",\"/static/livarava/img/neurons/person.png\"],[15,\"alt\",[31,[[34,0,[\"fullname\"]]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[14,0,\"card-block\"],[12],[2,\"\\n\"],[6,[37,2],null,[[\"route\",\"model\"],[\"neuron.item.overview\",[35,0,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"h6\"],[14,0,\"card-title\"],[12],[1,[35,0,[\"fullname\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[10,\"p\"],[14,0,\"card-text small\"],[12],[2,[35,0,[\"about\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"item\",\"if\",\"link-to\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/user/user-card.hbs"
    }
  });
  _exports.default = _default;
});